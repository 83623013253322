<template>
  <v-card outlined dense :rounded="rounded">
    <v-toolbar class="pt-1" extension-height="16px" :height="height" flat dense>
      <v-toolbar-title class="dark--text text-subtitle-1">
        {{ title }}
      </v-toolbar-title>
      <template v-if="description" #extension>
        <div class="text-caption grey--text text--darken-2">
          {{ description }}
        </div>
      </template>
      <v-spacer />
      <v-btn v-if="value !== null && value.alerts !== null && value.alerts.length > 0" @click="showAlerts" text
        class="warning--text" small style="text-align: left;">
        <div style="width: 100%;">
          <small>{{ value.alerts.length }} <v-icon color="warning" x-small
              class="pr-1">mdi-alert-outline</v-icon></small>
        </div>
      </v-btn>
      <v-btn small text v-if="value !== null && value.type === 'table'" @click="exportToPDF()">
        {{ $t('pdf') }}
      </v-btn>
      <v-btn small text v-if="value !== null && value.type === 'table'" @click="exportToXLS()">
        {{ $t('xls') }}
      </v-btn>
    </v-toolbar>
    <div class="pl-4 pr-4 pb-2" v-if="value !== null && value.type === 'singleValue'">
      <div class="text-h5">
        {{ value.content }}
      </div>
    </div>
    <GChart class="gchart pl-4 pr-4 pb-6 pt-6" :settings="{ packages: ['bar'] }"
      v-if="value !== null && value.type === 'gchart' && value.content.chartType === 'bar'" type="ColumnChart"
      :data="value.content.chartData" :options="chartOptions" @ready="onGoogleChartReady"
      :create-chart="(el, google) => new google.charts.Bar(el)" />
    <v-data-table v-if="value !== null && value.type === 'table'" flat tile fixed-header :height="chartHeight"
      :ref="reference" sort-by="quantity" :sort-desc="true" :items-per-page="999999" :headers="value.content.headers"
      :items="value.content.rows" :caption="value.content.caption" :loading="value.loading"
      :no-data-text="value.content['no-data-text']" :hide-default-footer="value.content['hide-default-footer']"
      :group-by="value.content['group-by']" class="elevation-0 no-margin-table mb-0" />
    <v-skeleton-loader v-if="value === null || value.value === null" ref="skeleton" type="list-item-avatar"
      class="mx-auto mb-2" :max-height="height" />
  </v-card>
</template>

<script>

import { GChart } from 'vue-google-charts'

export default {
  name: 'TransientDash',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    disclaimer: {
      type: String,
      default: ''
    },
    namespace: {
      type: String,
      default: null
    },
    config: {
      type: Object,
      default: () => { }
    },
    startTimeInMillis: {
      type: Number,
      default: null
    },
    endTimeInMillis: {
      type: Number,
      default: null
    },
    channels: {
      type: Array,
      default: () => []
    },
    locations: {
      type: Array,
      default: () => []
    },
    fixedHeader: {
      type: Boolean,
      default: false
    },
    forcedChartHeight: {
      type: Boolean,
      default: false
    },
    timeIntervalFrom: {
      type: String,
      default: ''
    },
    timeIntervalTo: {
      type: String,
      default: ''
    },
    rounded: {
      type: String,
      default: '0'
    },
    height: {
      type: String,
      default: '30px'
    }
  },

  /*  [

    'title',
    'description',
    'disclaimer',
    'namespace',
    'config',
    'startTimeInMillis',
    'endTimeInMillis',
    'channels',
    'locations',
    'fixedHeader',
    'forcedChartHeight',
    'timeIntervalFrom',
    'timeIntervalTo',
  ], */
  components: { GChart },
  data() {
    return {
      value: null,
      googleChartsLib: null,
    }
  },
  computed: {
    chartOptions() {
      if (!this.googleChartsLib || this.value.content === null || !this.value.content.chartOptions) return null
      return this.googleChartsLib.charts.Bar.convertOptions(this.value.content.chartOptions)
    },
    chartHeight() {
      // Note that if fixedHeader prop is activated then the height of the chart will be set to 75% of viewport height: 75vh
      if (this.forcedChartHeight) {
        return this.forcedChartHeight
      }
      else if (this.fixedHeader && this.value !== undefined && this.value !== null && this.value.content !== undefined && this.value.content !== null && this.value.content.rows && this.value.content.rows.length > 0) {
        return '75vh'
      }
      return 'auto'
    },
    reference() {
      let reference = ''

      reference += this.namespace

      if (this.startTimeInMillis) {
        reference += this.startTimeInMillis.toString()
      }

      if (this.endTimeInMillis) {
        reference += this.endTimeInMillis.toString()
      }

      return reference
    }
  },
  methods: {
    showAlerts() {
      this.$store.commit('updateGenericDialog', {
        component: 'components/dash/DashAlerts',
        props: {
          alerts: this.value.alerts
        }
      })
    },
    onGoogleChartReady(chart, google) {
      this.googleChartsLib = google
    },
    getDashValue() {
      const payload = {
        namespace: this.namespace,
        startTimeInMillis: this.startTimeInMillis,
        endTimeInMillis: this.endTimeInMillis,
        timeIntervalFrom: this.timeIntervalFrom,
        timeIntervalTo: this.timeIntervalTo,
        config: this.config,
        channels: [],
        locations: []
      }
      if (this.channels) {
        payload.channels = this.channels
      }
      if (this.locations) {
        payload.locations = this.locations
      }
      this.$store.dispatch('getDashByNamespace', payload).then(dash => {
        try {
          dash.content = JSON.parse(dash.content)
        }
        catch (e) {
          // Do nothing
        }

        this.value = dash
      })
    },
    sanitizeContentForExport(sanitizeContentForExport) {
      return this.$worker.run((unsanitizedString) => {
        // unsanitizedString = unsanitizedString.replaceAll('.', '')
        // unsanitizedString = unsanitizedString.replaceAll(',', '')
        unsanitizedString = unsanitizedString.replaceAll('&nbsp;kr', '')
        return unsanitizedString
      }, [sanitizeContentForExport]).then(sanitized => {
        return sanitized
      })
    },
    exportToPDF() {
      const innerHTML = this.$refs[this.reference].$el.innerHTML
      const fileName = this.namespace.replaceAll('_', ' ') + ' ' + this.title
      this.$store.commit('exportPDF', {
        fileName: fileName,
        html: innerHTML
      })
    },
    exportToXLS() {
      const innerHTML = this.$refs[this.reference].$el.innerHTML
      const fileName = this.namespace.replaceAll('_', ' ') + ' ' + this.title
      this.sanitizeContentForExport(innerHTML).then(sanitizedContent => {
        console.log(sanitizedContent)
        this.$store.commit('exportStore/exportXLS', {
          fileName: fileName,
          html: sanitizedContent
        })
      })
    },
  },
  mounted() {
    this.getDashValue()
  }
}
</script>

<style>
table {
  margin-bottom: 0;
}
</style>
